import React, { ReactNode } from "react";
import { styled } from "../../stitches/stitches.config";

export type HideOnScreenVariants = "mobile" | "desktop";
export type HideOnScreenBreakpoints = "xs" | "sm" | "md" | "lg" | "l" | "xl";

interface HideOnScreenProps {
  children: ReactNode;
  variant?: HideOnScreenVariants;
  breakpoint: HideOnScreenBreakpoints;
}

const HideOnScreen = ({ variant, breakpoint, children }: HideOnScreenProps) => {
  if (variant === "mobile") {
    return <WrapperMobile breakpoint={breakpoint}>{children}</WrapperMobile>;
  }
  if (variant === "desktop") {
    return <WrapperDesktop breakpoint={breakpoint}>{children}</WrapperDesktop>;
  }
  return <>{children}</>;
};

const WrapperMobile = styled("div", {
  display: "block",

  variants: {
    breakpoint: {
      xs: {
        "@xs": {
          display: "none",
        },
      },
      sm: {
        "@sm": {
          display: "none",
        },
      },
      md: {
        "@md": {
          display: "none",
        },
      },
      lg: {
        "@lg": {
          display: "none",
        },
      },
      l: {
        "@l": {
          display: "none",
        },
      },
      xl: {
        "@xl": {
          display: "none",
        },
      },
    },
  },
});
const WrapperDesktop = styled("div", {
  display: "none",

  variants: {
    breakpoint: {
      xs: {
        "@xs": {
          display: "block",
        },
      },
      sm: {
        "@sm": {
          display: "block",
        },
      },
      md: {
        "@md": {
          display: "block",
        },
      },
      lg: {
        "@lg": {
          display: "block",
        },
      },
      l: {
        "@l": {
          display: "block",
        },
      },
      xl: {
        "@xl": {
          display: "block",
        },
      },
    },
  },
});

export default HideOnScreen;
