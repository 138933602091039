import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  detailSmall,
  heading200,
  paragraphTextSmall,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import Button from "../Button/Button";
import MailIcon from "./mailIcon.inline.svg";
import { submitFormToHubspot } from "../../../utils/submitFormToHubspot";

// interface FormInputs {
//   email: string;
// }

// const schema = yup.object().shape({
//   email: yup.string().email().required().defined(),
// });

const title = `Signup to our newsletter`;
const byline = `Stay up to to date with all of our latest product releases, events and publications.`;
const disclaimer = `You can unsubscribe anytime`;

const Newsletter = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6034553",
          formId: "5fc60c87-b383-4ac1-8590-6c0d66f2364e",
          target: "#hubspotForm",
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    }
  }, []);

  // const [failure, setFailure] = useState<boolean>(false);
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitting, isSubmitSuccessful },
  // } = useForm<FormInputs>({
  //   resolver: yupResolver(schema),
  // });
  // const onSubmit = async (data: FormInputs) => {
  //   if (isSubmitSuccessful) {
  //     return;
  //   }
  //   const success = await submitFormToHubspot(
  //     data,
  //     "5fc60c87-b383-4ac1-8590-6c0d66f2364e"
  //   );
  //   if (!success) {
  //     setFailure(true);
  //   }
  // };

  // const buttonText = () => {
  //   if (failure) {
  //     return "Failure";
  //   } else if (isSubmitting) {
  //     return "Submitting";
  //   } else if (isSubmitSuccessful) {
  //     return "Success";
  //   } else {
  //     return "Submit";
  //   }
  // };

  return (
    <Container>
      <ContentContainer>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
        <div id="hubspotForm"></div>
        {/* <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <Input {...register("email")} placeholder="Your Email" />
            <MailIconSVG />
            <DesktopButton
              size="small"
              tier="primary"
              disabled={isSubmitting || isSubmitSuccessful || failure}
              failure={failure}
              success={isSubmitSuccessful && !failure}
            >
              {buttonText()}
            </DesktopButton>
          </InputWrapper>
          <InputError>{errors.email?.message}</InputError>
          <MobileButton
            tier="primary"
            size="large"
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful || failure}
            failure={failure}
            success={isSubmitSuccessful && !failure}
          >
            {buttonText()}
          </MobileButton>
        </FormContainer>
        <Disclaimer>{disclaimer}</Disclaimer> */}
      </ContentContainer>
    </Container>
  );
};

export default Newsletter;

const Container = styled("div", {
  backgroundColor: "$blue100",
  //width: "100%",
  margin: "auto",
  maxWidth: 1140,
  borderRadius: 10,
  width: "90%",
  marginBottom:50,
  "@md": {
    borderRadius: 16,
    width: "90%",
    margin: "50px auto",
    backgroundColor: "$blue100",
  },
  select: {
    fontSize: "16px",
    lineHeight: "26px",
    padding: "16px 2px",
    borderRadius: "8px",
    outline: "none",
    fontFamily: "var(--fonts-inter)",
    fontWeight: 600,
    textIndent: "15px",
    border: "0px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    width: "100%",
    appearance: "none",
    paddingLeft:"20px"
  },
  option: {
    fontSize: "16px",
    textIndent: "15px",
    cursor: "pointer",
    width: "100%",

  },
  input: {
    "&[name='email']": {
      fontSize: "16px",
      lineHeight: "26px",
      padding: "16px 2px",
      borderRadius: "8px",
      outline: "none",
      fontFamily: "var(--fonts-inter)",
      fontWeight: 600,
      textIndent: "15px",
      border: "0px",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      width: "100%",
      appearance: "none"
    }
  },

  ".field label": { color: 'white', marginTop: '15px', display: 'block' },
  ".field label span": { color: 'white' },
  ul: {
    listStyle: "none",
    color: "white"
  },
  li: {
    color: "white"
  },
  p: {
    color: "white"
  },
  a: { color: 'white', textDecoration: 'underline' },
  ".hs-richtext": { color: "white", marginTop: "20px" },
  ".hs-form-booleancheckbox-display": { display: "flex!important" },
  ".actions": {textAlign:'center'},
  ".actions .hs-button": {
    color: "#0077FF",
    fontSize: "16px",
    lineHeight: "26px",
    padding: " 8px 16px",
    borderRadius: "8px",
      outline: "none",
      fontFamily: "var(--fonts-inter)",
      fontWeight: 600,
      border:"1px solid white",
      margin: "0 auto",
      cursor:'pointer'
  },
});

const ContentContainer = styled("div", {
  padding: 20,

});

const Title = styled("h4", {
  ...heading200,
  color: "$white",
  margin: 0,
  marginBottom: 12,
  letterSpacing: -0.58,
  textAlign: "center",
  "@md": {
    color: "$white"
  }
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  color: "$black80",
  margin: 0,
  opacity: 0.7,
  marginBottom: 32,
  textAlign: "center",
  "@md": {
    color: "$white"
  }
});

const FormContainer = styled("form", {
  display: "flex",
  flexDirection: "column",
});

const InputWrapper = styled("div", {
  position: "relative",
  width: "100%",
  margin: "auto",
  marginBottom: 12,
  "@md": {
    maxWidth: 430,
  },
});

const Input = styled("input", {
  ...paragraphTextSmall,
  color: "$black55",
  padding: "14px 20px 14px 43px",
  margin: 0,
  borderRadius: 8,
  border: "1px solid  $black55",
  width: "100%",
  boxSizing: "border-box",
  "@md": {
    border: 0,
  },
});

const MobileButton = styled(Button, {
  display: "block",
  "@md": {
    display: "none",
  },
  variants: {
    success: {
      true: {
        backgroundColor: "#30c964",
        cursor: "not-allowed",
        "&:hover": {
          backgroundColor: "#30c964",
        },
      },
      false: {},
    },
    failure: {
      true: {
        cursor: "not-allowed",
        backgroundColor: "#ff0000",
        "&:hover": {
          backgroundColor: "#ff0000",
        },
      },
      false: {},
    },
  },
});
const DesktopButton = styled(Button, {
  position: "absolute",
  right: 7,
  top: 6,
  display: "none",
  "@md": {
    display: "block",
  },
  variants: {
    success: {
      true: {
        backgroundColor: "#30c964",
        cursor: "not-allowed",
        "&:hover": {
          backgroundColor: "#30c964",
        },
      },
      false: {},
    },
    failure: {
      true: {
        cursor: "not-allowed",
        backgroundColor: "#ff0000",
        "&:hover": {
          backgroundColor: "#ff0000",
        },
      },
      false: {},
    },
  },
});

const InputError = styled("span", {});

const MailIconSVG = styled(MailIcon, {
  position: "absolute",
  top: 22,
  left: 20,
});

const Disclaimer = styled("p", {
  ...detailSmall,
  color: "$blsck80",
  opacity: 0.7,
  letterSpacing: -0.25,
  "@md": {
    color: "$white"
  }
});
