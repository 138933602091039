import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { heading300,heading150, paragraphTextMedium } from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import { slugify } from "../../utils/slugify";
import Button from "../shared/Button/Button";
import Card from "../shared/Card/Card";
import MockImage from "./assets/case.studies.png";
import HideOnScreen from "../shared/HideOnScreen";

interface Props {
  headingDetails?: {
    title: string;
    byline: string;
    linkPath: string;
  };
  data: { [key: string]: any };
  mobileHidden?: boolean;
}

const KnowledgeCentrePreview = ({
  headingDetails,
  data,
  mobileHidden = true,
}: Props) => {
  return (
    <Container>
      {headingDetails && (
        <HeadingContainer>
          <Title>{headingDetails.title}</Title>
          <HideOnScreen variant="mobile" breakpoint="md">
          <img style={{width:"100%"}}
          src={MockImage}
          alt={headingDetails.title}
        /></HideOnScreen>
          <BylineContainer>
            <Byline>{headingDetails.byline}</Byline>
            <Link to={headingDetails.linkPath}>
              <ButtonWrapper>
                <Button size="medium" tier="bordered" arrow>
                  View all
                </Button>
              </ButtonWrapper>
            </Link>
          </BylineContainer>
        </HeadingContainer>
      )}
      <CardsContainer mobileHidden={mobileHidden}>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const edgeData = edge.node.frontmatter;
          return (
            <Card
              key={edgeData.title}
              title={edgeData.title}
              date={edgeData.date}
              details={
                edgeData.description
                  ? {
                      description: edgeData.description,
                      pdfLink: edgeData.pdf.publicURL,
                    }
                  : {
                      blogLink: `/newsroom/${slugify(edgeData.title)}`,
                    }
              }
              image={
                edgeData.image ? getImage(edgeData.image.childImageSharp) : null
              }
              thirds
            />
          );
        })}
      </CardsContainer>
    </Container>
  );
};

export default KnowledgeCentrePreview;

const Container = styled("div", {
  maxWidth: 1140,
  margin: "auto",
  width: "90%",
  paddingBottom: 15,
  paddingTop: 0,
  "@md": {
    width: "95%",
    padding: "20px 0 100px 0",
  },
});
const HeadingContainer = styled("div", {
  paddingTop: 75,
});
const ButtonWrapper = styled("div", {});
const BylineContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "flex-start",
  marginBottom: 42,
  "@md": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
const CardsContainer = styled("div", {
  margin: "auto",
  marginTop: 26,
  width: "100%",
  "@md": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  variants: {
    mobileHidden: {
      true: {
        display: "none",
        "@md": {
          display: "flex",
        },
      },
      false: {},
    },
  },
});

const Title = styled("h4", {
  ...heading150,
  marginBottom: 16,
  maxWidth: 780,
  "@md": {
    ...heading300,
    marginTop: 25,
  },
});

const Byline = styled("p", {
  ...paragraphTextMedium,
  margin: 0,
  marginBottom: 16,
  "@md": {
    width: "64%",
  },
});
