import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import dateFormat from "dateformat";
import { styled } from "../../../stitches/stitches.config";
import {
  date,
  detailSmall,
  heading100,
} from "../../../stitches/combinedStyles";
import ArrowRightIcon from "../Button/arrow_right.inline.svg";
import { Link } from "gatsby";

interface PdfDetails {
  pdfLink: string;
  description: string;
}

interface BlogDetails {
  blogLink: string;
}

export interface CardProps {
  title: string;
  date: string;
  image?: IGatsbyImageData | null;
  details: PdfDetails | BlogDetails;
  thirds?: boolean;
}

const Card = ({ title, date, image, details, thirds = false }: CardProps) => {
  return (
    <Container thirds={thirds} borderTop={!image}>
      {image && (
        <ImageContainer>
          <Image image={image} alt="" />
        </ImageContainer>
      )}
      <ContentContainer>
        <Title className="card-title">{title}</Title>
        <Date   className="card-date">
          {dateFormat(date, "mmmm")} • {dateFormat(date, "yyyy")}
        </Date>
        {"blogLink" in details ? (
          <ButtonContainer  className="card-buttoncontainer">
            <InternalButton to={details.blogLink}>
              Read Article
              <IconContainer>
                <ArrowRight />
              </IconContainer>
            </InternalButton>
          </ButtonContainer>
        ) : (
          <>
            <Description>{details.description}</Description>
            <ButtonContainer   className="card-buttoncontainer">
              <ExternalButton
                href={details.pdfLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open PDF
                <IconContainer>
                  <ArrowRight />
                </IconContainer>
              </ExternalButton>
            </ButtonContainer>
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

export default Card;

const Container = styled("div", {
  width: "100%",
  padding: "8px 12px 8px 8px",
  backgroundColor: "$white",
  marginBottom: 16,
  borderRadius: 8,
  overflow: "hidden",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  "@md": {
    border: 0,
    width: "49%",
    padding: 0,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  variants: {
    thirds: {
      true: {
        "@md": {
          width: "32%",
          "&:not(:last-child)": {
            marginRight: "1%",
          },
        },
      },
    },
    borderTop: {
      true: {
        borderTop: "2px solid rgba(26, 26, 26, 0.1)",
      },
      false: {},
    },
  },
});

const ImageContainer = styled("div", {
  width: "100%",
  height: "auto",
  marginRight: 12,
  borderRadius: 4,
  overflow: "hidden",
  "@md": {
    borderRadius: 0,
    marginRight: 0,
    width: "100%",
    height: "100%",
    maxHeight: 250,
  },
});

const ContentContainer = styled("div", {
  position: "relative",
  width: "100%",
  flex: "1 0 10%",
 
  "@md": {
    borderLeft: "2px solid rgba(26, 26, 26, 0.1)",
    borderRight: "2px solid rgba(26, 26, 26, 0.1)",
    borderBottom: "2px solid rgba(26, 26, 26, 0.1)",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: "16px 25px 36px 25px",
  },
});

const Title = styled("div", {
  ...heading100,
  fontSize: 16,
  lineHeight: "20px",
  marginBottom: 0,
  marginTop: 10,
});

const Date = styled("div", {
  ...date,
  color: "$black100",
  paddingTop: 8,
  paddingBottom: 0,
  "@md": {
    padding: "12px 0",
  },  
});

const Image = styled(GatsbyImage, {
  width: "100%",
  height: "100%",
  "& img": {
    borderRadius: 4,
    overflow: "hidden",
  },
  "@md": {
    height: 250,
    "& img": {
      borderRadius: 0,
      overflow: "hidden",
    },
  },
});

const Description = styled("p", {
  ...detailSmall,
  display: "block",
  fontSize:14,
  lineHeight:"28px",
  marginTop: 2,
  "@md": {
    display: "block",
  },
});

const ButtonContainer = styled("div", {
  marginTop: 8,
  "@md": {
    position: "absolute",
    bottom: 15,
    left: 25,
  },
});

const buttonStyles = {
  display: "inline-flex",
  alignItems: "center",
  fontFamily: "$opensans",
  fontWeight: 600,
  "& svg": {
    path: {
      fill: "$blue100",
    },
  },
};

const InternalButton = styled(Link, {
  ...buttonStyles,
});
const ExternalButton = styled("a", {
  ...buttonStyles,
});

const IconContainer = styled("div", { paddingLeft: 10 });

const ArrowRight = styled(ArrowRightIcon, {});
